import moment from "moment-timezone";

export function getMinDate(text) {
  let date = new Date();
  return getCalendarDate(date);
}

export function getMaxDate() {
  let date = new Date();
  date.setMonth(date.getMonth() + 4);
  return getCalendarDate(date);
}

export function getCalendarDate(d) {
  return {
    day: d.getDate(),
    month: d.getMonth() + 1,
    year: d.getFullYear(),
  };
}

export function getFormattedDate(d, includeTime = false, settings = {}) {
  const addLeadingZeros = (num, totalLength) => {
    return String(num).padStart(totalLength, "0");
  };

  let res = "";
  let isHourly = true;

  if (d) {
    res = `${d.year}-${addLeadingZeros(d.month, 2)}-${addLeadingZeros(
      d.day,
      2
    )}`;
  }
  if (d?.time && isHourly && includeTime) {
    res += ` ${d.time}`;
  }
  return res;
}

export function safeDateRange(range) {
  if (JSON.stringify(range.from) == JSON.stringify(range.to)) {
    let t = range.to;
    t = getFormattedDate(t);

    t = new Date(t);
    t.setDate(t.getDate() + 1);

    t = getCalendarDate(t);
    return { ...range, to: t };
  }
  return range;
}

export function getTimeArray(range, type) {
  let res = [];
  let gap = 30;

  for (let i = 0; i < 48; i++) {
    let s = moment();
    s.set("hour", 0);
    s.set("second", 0);
    s.set("minute", i * gap);
    let temp = { title: s.format("HH:mm A"), value: s.format("HH:mm:ss") };

    if (type == "from" && range.from) {
      let d = range.from;
      d = `${getFormattedDate(d)} ${s.format("HH:mm:ss")}`;
      if (moment(d).isAfter(moment())) {
        res.push(temp);
      }
    }

    if (type == "to" && range.to) {
      let f = range.from;
      f = `${getFormattedDate(f)} ${f?.time}`;

      let d = range.to;
      d = `${getFormattedDate(d)} ${s.format("HH:mm:ss")}`;
      if (moment(d).isAfter(moment(f).add(30, "minutes"))) {
        res.push(temp);
      }
    }
  }
  return res;
}

export function getFormattedDateRange(range) {
  if (range.from && range.to) {
    let f = range.from;
    f = getFormattedDate(range.from);
    f = moment(f);

    let t = range.to;
    t = getFormattedDate(range.to);
    t = moment(t);

    return `${f.format("D MMM")} - ${t.format("D MMM")}`;
  }
  return null;
}
