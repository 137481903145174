import React, { useState, useContext, useEffect } from "react";
import HomeBannerSearch from "../Components/homeBannerSearch";
import { SettingsContext } from "../DataFactory/useSettings";
import GView from "../Elements/GView";
import HomeContent from "../Components/homeContent";
import { ApiLoginService, ImgUrl } from "../services";
import GText from "../Elements/GText";
import ItemView from "../Elements/gItemListView";
import ItemFiltersView from "../Components/itemFilters";
import ItemFilterPopup from "../Components/itemFilterPopup";
import useWindowSize from "../DataFactory/useWindowSize";
import { useNavigate } from "react-router-dom";
import { UserLocContext } from "../DataFactory/useLocation";
import useDataFctory from "../useDataFactory";
import Placeholder from "../Placeholders/Placeholder";
import Modal from "react-modal";
import { GCardShimmer } from "../Elements/gShimmer";
import GButton from "../Elements/GButton";
import { ReactComponent as FilterIcon } from "../assets/images/filter.svg";
import GItemsSlider from "../Elements/gItemsSliderView";
import { ReactComponent as ListViewIcon } from "../assets/images/listView.svg";
import { ReactComponent as MapViewIcon } from "../assets/images/mapview.svg";
import styled from "styled-components";
import ItemListMapView from "./itemListMapView";
import { StringsContext } from "../DataFactory/useStrings";

const IconView = styled.div`
  display: flex;
  gap: 10px;
  justify-content: end;
  button.linkable {
    border: 1px solid #777;
    padding: 5px;
    border-radius: 4px;
    width: 35px;
    height: 34px;
  }
  svg {
    width: 20px;
    height: 20px;
  }

  & button.selected {
    fill: ${({ theme }) => theme.body};
    border: 1px solid ${({ theme }) => theme.body};
  }
`;

const Home = () => {
  const navigate = useNavigate();
  const { settings } = useContext(SettingsContext);
  const [strings] = useContext(StringsContext);
  const { userLocation } = useContext(UserLocContext);
  const { width } = useWindowSize();
  const { data: filtersData } = useDataFctory("product_filters", false);
  const [ifvisible, setIFVisible] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedFiltersData, setSelectedFiltersData] = useState([]);
  const [page, setPage] = useState("1");
  const [homePageData, setHomePageData] = useState({});
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedView, setSelectedView] = useState("list");

  useEffect(() => {
    setSelectedFiltersData([...filtersData]);
  }, [filtersData]);

  const getHomePageData = () => {
    ApiLoginService("GET", `c/homepage`, {
      type: "web",
      lat: userLocation?.lat || "",
      lng: userLocation.lng || "",
      ...selectedFilters,
    })
      .then((response) => {
        console.log(response);
        if (response.status_code === 1) {
          setHomePageData(response);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (userLocation?.lat) {
      setIsLoading(true);
      getHomePageData();
    } else {
      setIsLoading(false);
    }
  }, [userLocation, selectedFilters]);

  return (
    <>
      <GView
        className="main-content"
        style={{ padding: width > 767 ? "20px 0" : "20px" }}
      >
        <GView
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            height: "auto",
            minHeight: "400px",
            backgroundImage: `url(${
              ImgUrl("website") + "/" + settings?.web?.web_home_header
            })`,
            backgroundSize: "cover",
            backgroundPosition: "100%",
            backgroundRepeat: "no-repeat",
            borderRadius: "5px",
            textAlign: "center",
          }}
        >
          <GText
            g2="true"
            bold
            text={settings?.web?.web_home_title}
            style={{
              color: settings?.web?.web_home_header ? "#fff" : "#000",
              marginTop: "auto",
              textShadow: settings?.web?.web_home_header
                ? "0.5px 0.5px #f9f9f9"
                : "none",
              fontFamily: "Open Sans, sans-serif",
              fontSize: width > 767 ? "28px" : "24px",
            }}
          />
          {settings?.web?.web_home_desc && (
            <GText
              text={settings?.web?.web_home_desc}
              style={{
                padding: "10px 0",
                fontSize: "16px",
                color: settings?.web?.web_home_header ? "#fff" : "#000",
                textShadow: settings?.web?.web_home_header
                  ? "0.5px 0.5px #f9f9f9"
                  : "none",
              }}
            />
          )}

          <HomeBannerSearch />
        </GView>

        {userLocation?.lat && (
          <>
            <IconView style={{ padding: "20px 0 10px" }}>
              <GButton
                variant={
                  selectedView === "list" ? "linkable selected" : "linkable"
                }
                onClick={() => {
                  setIsLoading(true);
                  setSelectedView("list");
                  setTimeout(() => {
                    setIsLoading(false);
                  }, 1000);
                }}
              >
                <ListViewIcon />
              </GButton>
              <GButton
                variant={
                  selectedView === "map" ? "linkable selected" : "linkable"
                }
                onClick={() => setSelectedView("map")}
              >
                <MapViewIcon />
              </GButton>
            </IconView>

            {selectedView === "map" ? (
              <ItemListMapView filtersData={selectedFiltersData} />
            ) : (
              <>
                <GView style={{ padding: "10px 0 30px" }}>
                  <GView
                    style={{
                      display: width > 767 ? "-webkit-box" : "",
                      paddingTop: "20px",
                    }}
                  >
                    {selectedFiltersData &&
                      selectedFiltersData.length > 0 &&
                      width > 767 && (
                        <GView
                          style={{
                            flex:
                              width > 1200 && width <= 1550
                                ? "25%"
                                : width > 1550
                                ? "20%"
                                : "30%",
                          }}
                        >
                          <ItemFiltersView
                            data={selectedFiltersData}
                            applyBtn={true}
                            submitBtn={false}
                            onSubmit={(sValues) => {
                              console.log(sValues);

                              let fData = {};
                              selectedFiltersData.map((f) => {
                                f.selectedVal = sValues[f.identifier] || "";
                                console.log(sValues[f.identifier]);
                                fData[f?.identifier?.toString()] =
                                  sValues[f.identifier] || "";
                                //   }
                              });

                              console.log(fData);
                              setSelectedFiltersData([...selectedFiltersData]);
                              setSelectedFilters(fData);
                            }}
                          />
                        </GView>
                      )}
                    <GView
                      style={{
                        flex:
                          filtersData && filtersData.length > 0
                            ? width > 1200 && width <= 1550
                              ? "75%"
                              : width > 1550
                              ? "80%"
                              : width <= 767
                              ? "100%"
                              : "70%"
                            : "100%",
                      }}
                    >
                      {isLoading === true ? (
                        <GCardShimmer />
                      ) : (
                        <>
                          {homePageData?.groups &&
                          homePageData?.groups.length > 0 ? (
                            <>
                              {homePageData?.groups.map((group, i) => {
                                return (
                                  <React.Fragment key={i}>
                                    <GView
                                      style={{
                                        padding:
                                          i == 0 ? "0 0 10px 0" : "20px 0 10px",
                                      }}
                                    >
                                      <GText
                                        g3
                                        semi
                                        text={group.title}
                                        style={{ margin: "10px 0" }}
                                      />
                                      <GItemsSlider
                                        data={group.products || []}
                                        settings={settings}
                                      />
                                    </GView>
                                    <hr
                                      style={{
                                        margin:
                                          homePageData?.groups.length == i + 1
                                            ? "10px 0 40px"
                                            : "10px 0",
                                        border: 0,
                                        borderBottom: "1px solid #e2e2e2",
                                      }}
                                    />
                                  </React.Fragment>
                                );
                              })}
                            </>
                          ) : (
                            ""
                          )}
                          <GView>
                            <GView
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <GText
                                g3
                                bold
                                text={`${homePageData?.data?.total || ""} ${
                                  strings.w_restaurants
                                }`}
                              />
                              {width <= 767 && (
                                <a
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: "auto",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  onClick={() => setIFVisible(true)}
                                >
                                  <FilterIcon
                                    style={{
                                      width: "18px",
                                      height: "18px",
                                    }}
                                  />
                                  <GText
                                    semi
                                    text={"Filters"}
                                    style={{
                                      paddingLeft: "5px",
                                    }}
                                  />
                                </a>
                              )}
                            </GView>
                            {homePageData?.data?.data &&
                            homePageData?.data?.data.length > 0 ? (
                              <>
                                <GView
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns:
                                      width <= 350
                                        ? "repeat(auto-fit, minmax(300px, 1fr))"
                                        : width > 1200 && width <= 1600
                                        ? homePageData?.data?.data.length > 1
                                          ? "repeat(auto-fit, minmax(300px, 1fr))"
                                          : "1fr 1fr"
                                        : width > 1600
                                        ? homePageData?.data?.data.length > 1
                                          ? "repeat(auto-fit, minmax(300px, 1fr))"
                                          : "1fr 1fr 1fr"
                                        : "repeat(auto-fit, minmax(300px, 1fr))",
                                    gridGap: "20px",
                                    padding: "20px 0",
                                  }}
                                >
                                  {homePageData.data.data.map((item, i) => {
                                    return (
                                      <ItemView
                                        key={i}
                                        data={item}
                                        settings={settings}
                                        onSelectItem={() => {
                                          navigate(`/restaurant/${item.id}`, {
                                            state: {
                                              title: item?.custom_fields?.title,
                                            },
                                          });
                                        }}
                                      />
                                    );
                                  })}
                                </GView>

                                {homePageData?.data.next_page_url && (
                                  <GButton
                                    variant="condensed"
                                    loading={btnDisabled}
                                    children={"Load More"}
                                    type="button"
                                    onClick={() => {
                                      setBtnDisabled(true);
                                      setPage(page + 1);
                                    }}
                                    style={{
                                      display: "flex",
                                      margin: "10px auto",
                                      minWidth: "150px",
                                    }}
                                  />
                                )}
                              </>
                            ) : (
                              <Placeholder type={"item"} />
                            )}
                          </GView>
                        </>
                      )}
                    </GView>

                    {width <= 576 ? (
                      <Modal
                        isOpen={ifvisible}
                        className="modal modalBottom"
                        overlayClassName="modal-overlay"
                        onRequestClose={() => setIFVisible(false)}
                        style={{
                          content: {
                            width: "100vw",
                            top: "50%",
                            left: "0",
                            right: "0",
                            bottom: "0",
                            height: "auto",
                          },
                        }}
                        contentLabel="Filter Modal"
                      >
                        <ItemFilterPopup
                          popup={true}
                          ifvisible={ifvisible}
                          setIFVisible={setIFVisible}
                          data={selectedFiltersData}
                          setSelectedFiltersData={setSelectedFiltersData}
                          setSelectedFilters={setSelectedFilters}
                        />
                      </Modal>
                    ) : (
                      ""
                    )}
                  </GView>
                </GView>
              </>
            )}

            <hr />
          </>
        )}

        <HomeContent
          data={settings?.plugins?.homepage}
          content={
            settings?.homepage_content
              ? JSON.parse(settings?.homepage_content)
              : settings?.homepage_content
          }
        />
      </GView>
    </>
  );
};

export default Home;
