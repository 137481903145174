import React, { useEffect, useState, useContext } from "react";
import "./App.css";
import "./assets/css/modal.css";
import "./assets/css/forms.css";
import Header from "./Parts/header";
import Footer from "./Parts/footer";
import Home from "./Pages/home";
import ItemList from "./Pages/itemList";
import ItemDetail from "./Pages/itemDetail";
import PrivacyPolicy from "./Pages/privacyPolicy";
import Terms from "./Pages/terms&Conditions";
import Faqs from "./Pages/faqs";
import Profile from "./Pages/profile";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import { StringsContext } from "./DataFactory/useStrings";
import { SettingsContext } from "./DataFactory/useSettings";
import { ThemeStore } from "./DataFactory/useTheme";
import Theme from "./DataFactory/theme";
import Modal from "react-modal";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from "./errorView";
import NotFound from "./notFound";
import { ImgUrl } from "./services";
import useLocalStorage from "./DataFactory/useLocalStorage";

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <ErrorPage error={error} resetErrorBoundary={resetErrorBoundary} />
    </div>
  );
}
const loadGoogleMapScript = (callback) => {
  if (
    typeof window.google === "object" &&
    typeof window.google.maps === "object"
  ) {
    callback();
  } else {
    const googleMapScript = document.createElement("script");
    googleMapScript.src =
      `https://maps.googleapis.com/maps/api/js?key=` +
      process.env.REACT_APP_GOOGLE_KEY +
      `&libraries=geometry,places&callback=mapCallbackFunc`;
    window.document.body.appendChild(googleMapScript);
    googleMapScript.addEventListener("load", callback);
  }
};

function App() {
  const location = useLocation();
  const { settings } = useContext(SettingsContext);
  const [loadMap, setLoadMap] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const language = useContext(StringsContext);
  const [lang, setLang] = useLocalStorage("language");

  useEffect(() => {
    loadGoogleMapScript(() => {
      setLoadMap(true);
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(location);
  }, [location]);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [language[3]]);

  useEffect(() => {
    if (settings) {
      let localisation = settings?.localisation
        ? JSON.parse(settings?.localisation)
        : settings?.localisation;
      if (!lang) {
        setLang("en");
        setLang(localisation?.options[0] || "en");
      }
      setIsLoading(false);
    }
  }, [settings]);

  const isSettings = () => {
    if (settings && settings?.currency_symbol) {
      document.title = location?.state?.title
        ? location.state?.title + " | " + settings?.project_name
        : settings?.project_name || "Unify";
      //grab favicon element by ID
      const favicon = document.getElementById("favicon");
      favicon.href = ImgUrl("web") + "/" + settings?.web?.web_favicon;
      setTimeout(function () {
        favicon.href = ImgUrl("web") + "/" + settings?.web?.web_favicon;
      }, 800);
      return true;
    }
  };

  Modal.setAppElement("#root");
  return (
    <ThemeStore>
      <Theme>
        <div className="App" id="webElement">
          {isSettings() === false ? (
            <>
              <div style={{ position: "relative", padding: "40px 0" }}>
                <Header />
              </div>

              <NotFound />
              <Footer />
            </>
          ) : (
            <>
              {isLoading == true || !loadMap ? (
                <div
                  style={{
                    padding: "25% 50%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <p>Loading...</p>
                </div>
              ) : (
                <>
                  <div style={{ position: "relative", padding: "40px 0" }}>
                    <Header />
                  </div>
                  <ErrorBoundary
                    FallbackComponent={ErrorFallback}
                    onReset={() => {
                      window.location.reload(false);
                    }}
                  >
                    <div
                      className="App-content"
                      style={{
                        flex: "1 1 auto",
                        paddingTop:
                          location.pathname === "/add-order" ||
                          location.pathname === "/"
                            ? "0"
                            : "20px",
                      }}
                    >
                      <Routes>
                        <Route exact path="/" element={<Home />}></Route>

                        {/* <Route path="/not-found" component={NotFound}></Route> */}
                        <Route
                          path="privacy-policy"
                          element={<PrivacyPolicy />}
                        ></Route>
                        <Route path="terms" element={<Terms />}></Route>
                        <Route path="faqs" element={<Faqs />}></Route>

                        <Route
                          path={"restaurants"}
                          element={<ItemList />}
                        ></Route>
                        <Route
                          path={"restaurant/:id"}
                          element={<ItemDetail />}
                        ></Route>
                        <Route path="profile/*" element={<Profile />}></Route>
                        <Route path="*" element={<Navigate to="/" replace />} />
                      </Routes>
                    </div>
                  </ErrorBoundary>
                  <Footer />
                </>
              )}
            </>
          )}
        </div>
      </Theme>
    </ThemeStore>
  );
}

export default App;
