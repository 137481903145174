import React, { useState, useContext, useEffect } from "react";
import { ApiService } from "../services";
import { SettingsContext } from "../DataFactory/useSettings";
import { UserLocContext } from "../DataFactory/useLocation";
import GView from "../Elements/GView";
import ItemView from "../Elements/gItemListView";
import ItemFiltersView from "../Components/itemFilters";
import ItemFilterPopup from "../Components/itemFilterPopup";
import useWindowSize from "../DataFactory/useWindowSize";
import GText from "../Elements/GText";
import { useSnackbar } from "notistack";
import Placeholder from "../Placeholders/Placeholder";
import { GCardShimmer } from "../Elements/gShimmer";
import GButton from "../Elements/GButton";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import GMap from "../Components/itemLocation";
import styled from "styled-components";
import LocationIcon from "../assets/images/marker.png";
import { ReactComponent as FilterIcon } from "../assets/images/filter.svg";
import { StringsContext } from "../DataFactory/useStrings";

const LocationDiv = styled.div`
  margin: 15px 0;
  border-radius: 4px;
  border: 1px solid #e2e2e2;
  svg {
    fill: ${({ theme }) => theme.body};
    height: "100%";
  }
`;

const ItemListMapView = (props) => {
  const navigate = useNavigate();
  const { settings } = useContext(SettingsContext);
  const [strings] = useContext(StringsContext);
  const { userLocation } = useContext(UserLocContext);
  const [isLoading, setIsLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const { width } = useWindowSize();
  const [ifvisible, setIFVisible] = useState(false);
  const [itemsData, setItemsData] = useState({
    data: [],
  });
  const [page, setPage] = useState("1");
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedFiltersData, setSelectedFiltersData] = useState([]);

  useEffect(() => {
    setSelectedFiltersData([...props.filtersData]);
  }, [props.filtersData]);

  function getItems() {
    let sendData = {
      lat: userLocation?.lat,
      lng: userLocation?.lng,
    };
    console.log({ ...sendData, ...selectedFilters });
    ApiService({
      method: "Post",
      route: "c/products?page=" + page,
      body: { ...sendData, ...selectedFilters },
    })
      .then((response) => {
        console.log(response);

        if (response.status === 26) {
          enqueueSnackbar(
            "NETWORK FAILED. Please check your internet connection."
          );
          return;
        } else {
          if (response?.data?.status_code === 1) {
            setItemsData(response.data.data);
          } else {
            enqueueSnackbar(response?.data?.message);
          }
        }

        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    setIsLoading(true);
    console.log(selectedFilters);
    if (page == 1) {
      getItems();
    } else {
      setPage("1");
    }
  }, [selectedFilters, userLocation]);

  useEffect(() => {
    if (page > 1) {
      let sendData = {
        lat: userLocation?.lat,
        lng: userLocation?.lng,
      };

      console.log(sendData);
      ApiService({
        method: "Post",
        route: "c/products?page=" + page,
        body: sendData,
      })
        .then((response) => {
          console.log(response);

          if (response.status === 26) {
            enqueueSnackbar(
              "NETWORK FAILED. Please check your internet connection."
            );
            return;
          } else {
            if (response?.data?.status_code === 1) {
              itemsData.data = [...itemsData.data, response.data.data];
              setItemsData({ ...itemsData });
            } else {
              enqueueSnackbar(response?.data?.message);
            }
          }

          setIsLoading(false);
          setBtnDisabled(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      getItems();
    }
  }, [page]);

  return (
    <GView style={{ padding: "0 0 30px" }}>
      <GView style={{ display: "flex", paddingTop: "20px" }}>
        {selectedFiltersData &&
          selectedFiltersData.length > 0 &&
          width > 767 && (
            <GView
              style={{
                flex:
                  width > 1200 && width <= 1550
                    ? "25%"
                    : width > 1550
                    ? "20%"
                    : "30%",
              }}
            >
              <ItemFiltersView
                data={selectedFiltersData}
                applyBtn={true}
                submitBtn={false}
                onSubmit={(sValues) => {
                  console.log(sValues);

                  let fData = {};
                  selectedFiltersData.map((f) => {
                    f.selectedVal = sValues[f.identifier] || "";
                    console.log(sValues[f.identifier]);
                    fData[f?.identifier?.toString()] =
                      sValues[f.identifier] || "";
                    //   }
                  });

                  console.log(fData);
                  setSelectedFiltersData([...selectedFiltersData]);
                  setSelectedFilters(fData);
                }}
              />
            </GView>
          )}
        <GView
          style={{
            flex:
              props.filtersData && props.filtersData.length > 0
                ? width > 1200 && width <= 1550
                  ? "75%"
                  : width > 1550
                  ? "80%"
                  : width <= 767
                  ? "100%"
                  : "70%"
                : "100%",
          }}
        >
          {isLoading === true ? (
            <GCardShimmer />
          ) : (
            <>
              <GView style={{ display: "flex", alignItems: "center" }}>
                <GText
                  g3
                  bold
                  text={`${itemsData?.total || ""} ${strings.w_restaurants}`}
                />
                {width <= 767 && (
                  <a
                    style={{
                      cursor: "pointer",
                      marginLeft: "auto",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={() => setIFVisible(true)}
                  >
                    <FilterIcon
                      style={{
                        width: "18px",
                        height: "18px",
                      }}
                    />
                    <GText
                      semi
                      text={"Filters"}
                      style={{
                        paddingLeft: "5px",
                      }}
                    />
                  </a>
                )}
              </GView>
              {itemsData.data.length === 0 ? (
                <Placeholder type={"item"} />
              ) : (
                <>
                  <GView style={{ width: "100%", height: "300px" }}>
                    <LocationDiv>
                      <GMap
                        lat={itemsData?.data[0]?.lat}
                        lng={itemsData?.data[0]?.lng}
                        address={"itemInfo?.address"}
                        height="calc(100vh - 200px)"
                        style={{ borderRadius: "4px" }}
                        markers={itemsData.data.map((item, i) => {
                          return {
                            id: item?.id,
                            lat: parseFloat(item?.lat),
                            lng: parseFloat(item?.lng),
                            icon: LocationIcon,
                            title: item?.custom_fields?.title || item?.title,
                            type: "address",
                            data: item,
                          };
                        })}
                        onSelectItemLocation={(itemInfo) => {
                          console.log(itemInfo);
                          navigate(`/restaurant/${itemInfo?.id}`, {
                            state: {
                              title: itemInfo?.custom_fields?.title,
                            },
                          });
                        }}
                      />
                    </LocationDiv>
                  </GView>
                  <GView
                    style={{
                      display: "grid",
                      gridTemplateColumns:
                        width <= 350
                          ? "repeat(auto-fit, minmax(300px, 1fr))"
                          : width > 1200 && width <= 1600
                          ? itemsData?.data.length > 1
                            ? "repeat(auto-fit, minmax(300px, 1fr))"
                            : "1fr 1fr"
                          : width > 1600
                          ? itemsData?.data.length > 1
                            ? "repeat(auto-fit, minmax(300px, 1fr))"
                            : "1fr 1fr 1fr"
                          : "repeat(auto-fit, minmax(300px, 1fr))",
                      gridGap: "20px",
                      padding: "20px 0",
                    }}
                  >
                    {itemsData.data.map((item, i) => {
                      return (
                        <ItemView
                          key={i}
                          data={item}
                          settings={settings}
                          onSelectItem={() => {
                            navigate(`/restaurant/${item.id}`, {
                              state: {
                                title: item?.custom_fields?.title,
                              },
                            });
                          }}
                        />
                      );
                    })}
                  </GView>

                  {itemsData.next_page_url && (
                    <GButton
                      variant="condensed"
                      loading={btnDisabled}
                      children={"Load More"}
                      type="button"
                      onClick={() => {
                        setBtnDisabled(true);
                        setPage(page + 1);
                      }}
                      style={{
                        display: "flex",
                        margin: "10px auto",
                        minWidth: "150px",
                      }}
                    />
                  )}
                </>
              )}
            </>
          )}
        </GView>
      </GView>

      {width <= 576 ? (
        <Modal
          isOpen={ifvisible}
          className="modal modalBottom"
          overlayClassName="modal-overlay"
          onRequestClose={() => setIFVisible(false)}
          style={{
            content: {
              width: "100vw",
              top: "50%",
              left: "0",
              right: "0",
              bottom: "0",
              height: "auto",
            },
          }}
          contentLabel="Filter Modal"
        >
          <ItemFilterPopup
            popup={true}
            ifvisible={ifvisible}
            setIFVisible={setIFVisible}
            data={selectedFiltersData}
            setSelectedFiltersData={setSelectedFiltersData}
            setSelectedFilters={setSelectedFilters}
          />
        </Modal>
      ) : (
        ""
      )}
    </GView>
  );
};

export default ItemListMapView;
