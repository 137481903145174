import React, { useState, useEffect } from "react";
import Placeholder from "./Placeholders/Placeholder";
import { GCardShimmer } from "./Elements/gShimmer";
import TableShimmer from "./Elements/gTableShimmer";
import { ApiService } from "./services";
import GEmpty from "./Elements/gEmpty";

const useDataFactory = (type, paginate = false, bodyData = {}) => {
  const EmptyPlaceholder = () => <Placeholder type={type} />;
  const LoginRequiredPlaceholder = () => <GEmpty />;
  const internetPlaceholder = () => (
    <Placeholder type={"internet"} action={refreshData} />
  );

  const [data, setData] = useState([]);
  const [fullData, setFullData] = useState({});
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [body, setBody] = useState(bodyData);
  const [pagination, setPagination] = useState({
    total: 0,
    current_page: 0,
    last_page: 1,
  });

  const [loginRequired, setLoginRequired] = useState(false);
  const [internetFailed, setInternetFailed] = useState(false);

  const fetchData = () => {
    if (paginate) {
      if (pagination.current_page == pagination.last_page) {
        return;
      }
      ApiService({
        method: "GET",
        route: routes[type],
        body: { ...body, ...{ page: pagination.current_page + 1 } },
      }).then((response) => {
        console.log(response);
        if (response.status === 26) {
          setInternetFailed(true);
          setLoading(false);
          return;
        } else {
          setInternetFailed(false);
        }

        if (response.status === 401) {
          setLoginRequired(true);
          setLoading(false);
          return;
        } else {
          setLoginRequired(false);
        }

        setLoading(false);
        setData([...data, ...response.data.data.data]);
        setPagination(response.data.data);
        setFullData(response.data);
      });
    } else {
      ApiService({
        method: "GET",
        route: routes[type],
        body: body,
      }).then((response) => {
        console.log(response);
        if (response.status === 26) {
          setInternetFailed(true);
          setLoading(false);
          return;
        } else {
          setInternetFailed(false);
        }

        if (response.status === 401) {
          setLoginRequired(true);
          setLoading(false);
          return;
        } else {
          setLoginRequired(false);
        }

        setData(response.data.data);
        setFullData(response.data);
        setLoading(false);
      });
    }
  };

  const loadMore = () => {
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, [refresh]);

  const refreshData = (filters = {}) => {
    setBody(filters);
    setPagination({
      total: 0,
      current_page: 0,
      last_page: 1,
    });
    setData([]);
    setLoading(true);
    setRefresh(!refresh);
  };

  return {
    loading: loading,
    Shimmer: GCardShimmer,
    TableShimmer: TableShimmer,
    Placeholder: internetFailed
      ? internetPlaceholder
      : loginRequired
      ? LoginRequiredPlaceholder
      : EmptyPlaceholder,
    data: data,
    loadMore: loadMore,
    pagination: pagination,
    refreshData: refreshData,
    fullData: fullData,
  };
};

export default useDataFactory;

const routes = {
  homepage: "c/homepage",
  order: "c/orders",
  social: "c/social",
  faqs: "c/faqs",
  address: "c/addresses",
  reviews: "c/reviews",
  cancelReasons: "c/cancel-reasons",
  product_filters: "c/product/filters",
};
