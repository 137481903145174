import React, { useState, useEffect, useContext } from "react";
import { ApiService, ImgUrl } from "../services";
import { useSnackbar } from "notistack";
import { ReactComponent as ArrowBackIos } from "../assets/images/arrowBack.svg";
import GButton from "../Elements/GButton";
import { GCardShimmer } from "../Elements/gShimmer";
import { GInlineContent, GTableContent } from "../Elements/gContent";
import Modal from "react-modal";
import CancelReasonsView from "../Components/orderCancelReasons";
import OrderFeedback from "../Components/orderFeedback";
import GText from "../Elements/GText";
import GView from "../Elements/GView";
import OrderMessages from "../Components/orderMesaages";
import { ReactComponent as PhoneIcon } from "../assets/images/phone.svg";
import { ReactComponent as ChatIcon } from "../assets/images/chat.svg";
import { ReactComponent as NavigateIcon } from "../assets/images/navigation.svg";
import GInfo from "../Elements/gInfo";
import StartCheckListView from "../Components/startChecklistPopup";
import { StringsContext } from "../DataFactory/useStrings";
import styled from "styled-components";
import GMap from "../Components/itemLocation";

const LocationDiv = styled.div`
  svg {
    fill: ${({ theme }) => theme.body};
  }
`;

function getIOSVersion() {
  var match = window.navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
  return match ? match[1] : undefined;
}

export default function OrderDetail(props) {
  const [strings] = useContext(StringsContext);
  const [orderData, setOrderData] = useState({});
  const [startCheckListVisible, setStartCheckListVisible] = useState(false);
  const [ocrvisible, setOcrVisible] = useState(false);
  const [ofvisible, setOfvisible] = useState(false);
  const [omvisible, setOmvisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [orderMessages, setOrderMessages] = useState([]);
  const { status_display, host, order_review, order_fields, cancelled_log } =
    orderData;
  const { enqueueSnackbar } = useSnackbar();

  const getOrderDetail = () => {
    ApiService({ method: "GET", route: "c/orders/" + props.linkedId })
      .then((response) => {
        console.log(response);

        if (response.status === 26) {
          enqueueSnackbar(
            "NETWORK FAILED. Please check your internet connection."
          );
          return;
        } else {
          if (response?.data?.status_code === 1) {
            setOrderData(response.data.data);
            setIsLoading(false);
            setOrderMessages(response.data.data?.order_messages || []);
          } else {
            enqueueSnackbar(response?.data?.message);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getOrderDetail();
  }, [props.linkedId]);

  const handleUpdateStatus = (status) => {
    ApiService({
      method: "POST",
      route: "c/order/" + status + "/" + props.linkedId,
    })
      .then((response) => {
        console.log(response);

        if (response.status === 26) {
          enqueueSnackbar(
            "NETWORK FAILED. Please check your internet connection."
          );
          return;
        } else {
          if (response?.data?.status_code === 1) {
            getOrderDetail();
            setStartCheckListVisible(false);
            props.onStatusUpdate();
          } else {
            enqueueSnackbar(response?.data?.message);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  if (props.orderInfovisible === false) {
    return null;
  }
  return (
    <React.Fragment>
      <div className="modal-header">
        <ArrowBackIos
          className="backIcon"
          onClick={() => props.setOrderInfovisible(false)}
        />{" "}
        {strings.w_booking} #{props.linkedId}
        {status_display?.title === "Pending" && (
          <GButton
            variant="linkable"
            children={strings.w_cancel_booking}
            type="button"
            style={{ marginLeft: "auto" }}
            onClick={() => setOcrVisible(true)}
          />
        )}
        {status_display?.title === "Completed" && !order_review ? (
          <GButton
            variant="linkable"
            children={strings.w_feedback}
            type="button"
            style={{ marginLeft: "auto" }}
            onClick={() => setOfvisible(true)}
          />
        ) : (
          ""
        )}
      </div>

      <div className="modal-body" style={{ padding: "0 25px" }}>
        {isLoading === true ? (
          <GCardShimmer />
        ) : (
          <React.Fragment>
            {orderData?.product?.images &&
              orderData?.product?.images.length > 0 && (
                <img
                  src={
                    ImgUrl("item") + "/" + orderData?.product?.images[0]?.photo
                  }
                  style={{ width: "100%" }}
                />
              )}
            <GView
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "10px 0 15px",
                alignItems: "center",
              }}
            >
              <GView style={{ flex: "1" }}>
                <GText
                  g4
                  semi
                  text={orderData?.product_title}
                  style={{ margin: "0px 0" }}
                />
                <GText
                  g5
                  med
                  text={`${strings.w_created_at} :  ${orderData.created}`}
                  style={{ margin: "0px 0", fontSize: "13.5px" }}
                />
              </GView>
              <span
                className="orderStatus"
                style={{
                  background: `#${status_display?.color1}`,
                  color: "#fff",
                  marginLeft: "15px",
                  height: "fit-content",
                  borderRadius: "20px",
                  padding: "3px 12px",
                  fontSize: "14px",
                }}
              >
                #{orderData?.id} - {status_display?.title}
              </span>{" "}
            </GView>

            {status_display?.title === "Cancelled" && (
              <GView
                style={{
                  background: "rgba(249, 109, 119, 0.2)",
                  borderRadius: "5px",
                  boxSizing: "border-box",
                  marginBottom: "10px",
                  padding: "10px",
                }}
              >
                {cancelled_log?.user_type && (
                  <GText
                    g6
                    bold
                    style={{ color: "#F96D77" }}
                    text={`${strings.w_cancelled_by} - ${
                      cancelled_log?.user_type == 1
                        ? strings.w_you
                        : cancelled_log?.user_type == 2
                        ? strings.w_host
                        : cancelled_log?.user_type == 3
                        ? "Driver"
                        : cancelled_log?.user_type == 4
                        ? strings.w_admin
                        : "-"
                    }`}
                  />
                )}
                <GText
                  style={{
                    color: "#F96D77",
                    fontWeight: "450",
                    letterSpacing: "0.2px",
                    fontSize: "13.5px",
                  }}
                  text={cancelled_log.reason}
                />
              </GView>
            )}

            <hr style={{ border: 0, borderBottom: "3px solid #f4f5f9" }} />
            <GText
              g6
              med
              text={strings.w_booking_info}
              style={{
                textTransform: "uppercase",
                fontStyle: "italic",
                margin: "15px 0 10px",
              }}
            />
            {orderData?.starts && (
              <GInlineContent
                title={strings.w_starts}
                value={orderData.starts_display}
              />
            )}
            {orderData?.ends && (
              <GInlineContent
                title={strings.w_ends}
                value={orderData.ends_display}
              />
            )}

            {orderData?.order_units && orderData?.order_units.length == 1 && (
              <GInlineContent
                title={strings.w_guests}
                value={orderData?.order_units[0].users}
              />
            )}

            {orderData?.name && (
              <GInlineContent title={strings.w_name} value={orderData.name} />
            )}

            {orderData?.notes && (
              <GInlineContent
                title={strings.w_instructions}
                value={orderData.notes}
              />
            )}

            <hr style={{ border: 0, borderBottom: "3px solid #f4f5f9" }} />
            <div>
              <GText
                g6
                med
                text={strings.w_host_details}
                style={{
                  textTransform: "uppercase",
                  fontStyle: "italic",
                  margin: "15px 0 10px",
                }}
              />
              <div style={{ display: "flex", paddingBottom: "10px" }}>
                <GInfo
                  title={host?.title}
                  subtitle={host?.phone}
                  imgType="host"
                  photo={host?.thumb_photo}
                  width="50px"
                  height="50px"
                  titleStyle={{ fontSize: "16.5px" }}
                />
                <div
                  style={{
                    width: "80px",
                    textAlign: "right",
                    display: "inline-flex",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`tel: ${host?.phone}`}
                    style={{ display: "inherit" }}
                  >
                    <PhoneIcon style={{ width: "28px", height: "28px" }} />
                  </a>
                  <ChatIcon
                    onClick={() => setOmvisible(true)}
                    style={{ width: "28px", height: "28px", cursor: "pointer" }}
                  />
                </div>
              </div>
            </div>

            <hr style={{ border: 0, borderBottom: "3px solid #f4f5f9" }} />

            <GText
              g6
              med
              text={strings.s_where_you_will_be}
              style={{
                textTransform: "uppercase",
                fontStyle: "italic",
                margin: "15px 0 10px",
              }}
            />
            <LocationDiv>
              <GMap
                lat={orderData?.product?.lat}
                lng={orderData?.product?.lng}
                address={orderData?.product?.address}
                style={{
                  height: "200px",
                  border: "1px solid #e2e2e2",
                  borderRadius: "4px",
                }}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "10px 0",
                }}
              >
                <GText text={orderData?.product?.address} />
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    orderData?.product?.address
                      ? `https://www.google.com/maps?q=${orderData?.product?.lat},${orderData?.product?.lng}`
                      : ""
                  }
                  style={{ marginLeft: "auto" }}
                >
                  <NavigateIcon
                    style={{
                      margin: "0 8px",
                      width: "28px",
                      height: "28px",
                      cursor: "pointer",
                      fill: "#4A90E2",
                    }}
                  />
                </a>
              </div>
            </LocationDiv>
          </React.Fragment>
        )}

        {getIOSVersion() >= 14 && <div style={{ height: "80px" }}></div>}
      </div>

      <Modal
        isOpen={startCheckListVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setStartCheckListVisible(false)}
        style={{
          content: {
            left: "auto",
            right: "0",
            top: "0",
            bottom: "0",
            width: "450px",
          },
        }}
        contentLabel="Start checklist Modal"
      >
        <StartCheckListView
          startCheckListVisible={startCheckListVisible}
          setStartCheckListVisible={setStartCheckListVisible}
          linkedId={orderData.id}
          settings={props.settings}
          onClick={() => {
            handleUpdateStatus("start");
          }}
        />
      </Modal>

      <Modal
        isOpen={ocrvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOcrVisible(false)}
        style={{
          content: {
            left: "auto",
            right: "0",
            top: "0",
            bottom: "0",
            width: "450px",
          },
        }}
        contentLabel="Order cancel Modal"
      >
        <CancelReasonsView
          ocrvisible={ocrvisible}
          setOcrVisible={setOcrVisible}
          linkedId={orderData.id}
          onSubmit={() => {
            getOrderDetail();
            props.onCancelOrder();
          }}
        />
      </Modal>

      <Modal
        isOpen={ofvisible}
        className="modal"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOfvisible(false)}
        style={{
          content: {
            width: "375px",
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "5px!important",
          },
        }}
        contentLabel="Booking Feedback Modal"
      >
        <OrderFeedback
          ofvisible={ofvisible}
          setOfvisible={setOfvisible}
          orderId={orderData.id}
          onSubmit={getOrderDetail}
        />
      </Modal>

      <Modal
        isOpen={omvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setOmvisible(false)}
        style={{
          content: {
            left: "auto",
            right: "0",
            top: "0",
            bottom: "0",
            width: "450px",
            padding: "20px 0 0",
          },
        }}
        contentLabel="Order message Modal"
      >
        <OrderMessages
          omvisible={omvisible}
          setOmvisible={setOmvisible}
          orderId={orderData.id}
          data={orderMessages}
          onSubmit={(data) => {
            console.log([...orderMessages, data]);
            setOrderMessages([...orderMessages, data]);
          }}
        />
      </Modal>
    </React.Fragment>
  );
}
