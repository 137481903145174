import React, { useState, useContext, useEffect } from "react";
import { ApiService, ImgUrl, addDefaultSrc } from "../services";
import GView from "../Elements/GView";
import {
  useParams,
  useLocation,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import { GItemInfoShimmer } from "../Elements/gShimmer";
import GText from "../Elements/GText";
import { useSnackbar } from "notistack";
import { SettingsContext } from "../DataFactory/useSettings";
import { UserLocContext } from "../DataFactory/useLocation";
import { UserSearchInfoContext } from "../DataFactory/useSearchInfo";
import styled from "styled-components";
import { ReactComponent as NavigateIcon } from "../assets/images/navigation.svg";
import { ReactComponent as SavedIcon } from "../assets/images/saved.svg";
import { ReactComponent as SavedEmptyIcon } from "../assets/images/saved-empty.svg";
import useWindowSize from "../DataFactory/useWindowSize";
import GButton from "../Elements/GButton";
import GInfo from "../Elements/gInfo";
import GMap from "../Components/itemLocation";
import Modal from "react-modal";
import ItemAmenities from "../Components/itemAmenities";
import GLightBox from "../Elements/gLightBox";
import SignUp from "../forms/signup";
import SignIn from "../forms/login";
import ForgotPassword from "../forms/forgotPassword";
import OrderCheckout from "./orderCheckout";
import { StringsContext } from "../DataFactory/useStrings";

const Div = styled.div`
  &.rating {
    p {
      margin: 2px 0;
      font-size: 14px;
      display: flex;
      align-items: baseline;
      svg {
        width: 12px;
        fill: #333;
      }

      &.tag {
        background: ${({ theme }) => theme.body};
        color: #fff;
        padding: 2px 8px;
        font-size: 12px;
      }
    }
  }
`;

const LocationDiv = styled.div`
  svg {
    fill: ${({ theme }) => theme.body};
  }
`;

const SideBar = styled.div`
  @media (min-width: 768px) {
    &.sticky {
      position: fixed;
      top: 70px;
      width: 30% !important;
    }
  }
`;

const FavouriteView = styled.div`
  position: absolute;
  right: 15px;
  top: 25px;
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
  }
`;

const customStyles = {
  content: {
    top: "0",
    left: "auto",
    right: "0",
    bottom: "0",
    width: "400px",
    // transform: 'translate(0%, -50%)'
  },
};

const ItemDetail = () => {
  let { id } = useParams();
  const [searchParams] = useSearchParams();
  const [strings] = useContext(StringsContext);
  let navigate = useNavigate();
  const location = useLocation();
  const { settings } = useContext(SettingsContext);
  const { userLocation } = useContext(UserLocContext);
  const { searchInfo } = useContext(UserSearchInfoContext);
  const [isLoading, setIsLoading] = useState(true);
  const [itemInfo, setItemInfo] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const { width, height } = useWindowSize();
  const [itemAmenitiesVisible, setItemAmenitiesVisible] = useState(false);
  const [selectedImgPreview, setSelectedImgPreview] = useState(false);
  const [isImgPreview, setImgPreview] = useState(false);
  const [fpvisible, setFpvisible] = useState(false);
  const [signupvisible, setSignupvisible] = useState(false);
  const [signinvisible, setSigninvisible] = useState(false);
  const dO = ["address", "additional", "reviews"];
  const [favourite, setFavourite] = useState(false);

  useEffect(() => {
    console.log(itemInfo);
    console.log(userLocation);
    if (!userLocation?.lat && !itemInfo?.id) {
      navigate("/");
    } else {
      if (id) {
        ApiService({
          method: "POST",
          route: "c/products/" + id,
          body: {
            lat: userLocation?.lat,
            lng: userLocation?.lng,
            starts: searchInfo?.starts,
            ends: searchInfo?.ends,
          },
        })
          .then((response) => {
            console.log(response);

            if (response.status === 26) {
              enqueueSnackbar(
                "NETWORK FAILED. Please check your internet connection."
              );
              return;
            } else {
              if (response?.data?.status_code === 1) {
                setItemInfo(response.data.data);
                setFavourite(response.data.data.favourite);
              }
            }

            setIsLoading(false);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }, [userLocation, searchInfo, id, settings]);

  function myFunction() {
    var productSection = document.getElementById("productView");
    var sidebar = document.getElementById("sidebar");
    var stickyBottom = productSection?.clientHeight + 200;
    var stickyTop = productSection?.offsetTop - 100;
    var bottom = height;
    bottom = stickyBottom - bottom + 350;

    if (sidebar && (stickyTop || stickyBottom)) {
      if (stickyTop < window.pageYOffset && bottom > window.pageYOffset) {
        sidebar.classList.add("sticky");
      } else {
        sidebar.classList.remove("sticky");
      }
    }
  }
  useEffect(() => {
    if (width > 767) {
      window.addEventListener("scroll", myFunction);
    }
  }, [width]);

  const addToFavourite = () => {
    if (!settings?.user?.id) {
      setSigninvisible(true);
      return;
    }
    ApiService({
      method: "POST",
      route: "c/wishlist",
      body: { product_id: itemInfo.id },
    }).then((response) => {
      console.log(response);
      if (response.data.status_code === 1) {
        setFavourite(!favourite);
      } else {
        enqueueSnackbar(response.data.message);
      }
    });
  };
  const removeFavourite = () => {
    ApiService({
      method: "DELETE",
      route: "c/wishlist",
      body: { product_id: itemInfo.id },
    }).then((response) => {
      console.log(response);
      if (response.data.status_code === 1) {
        setFavourite(!favourite);
      } else {
        enqueueSnackbar(response.data.message);
      }
    });
  };

  return (
    <GView
      className="main-content"
      style={{ padding: width > 767 ? "20px 0" : "10px 20px" }}
    >
      {isLoading == true ? (
        <GItemInfoShimmer />
      ) : itemInfo?.id ? (
        <GView>
          <GText
            g3
            semi
            text={itemInfo?.custom_fields?.title || itemInfo?.title}
          />
          <GView
            style={{
              display: "flex",
              margin: "5px 0",
              flexDirection: "row",
            }}
          >
            <GText
              med
              text={`${itemInfo?.custom_fields?.desc} • ${itemInfo?.address}`}
            />
            <Div className="rating" style={{ marginLeft: "auto" }}>
              {itemInfo?.rating?.count == 0 ? (
                <p className="tag">{itemInfo?.rating?.display}</p>
              ) : (
                <p>
                  ★ &nbsp;
                  {itemInfo?.rating?.avg} • {itemInfo?.rating?.count} reviews
                </p>
              )}
            </Div>
          </GView>

          {itemInfo?.images && itemInfo?.images.length > 0 && (
            <GView style={{ position: "relative" }}>
              <FavouriteView>
                {favourite == true ? (
                  <SavedIcon onClick={() => removeFavourite()} />
                ) : (
                  <SavedEmptyIcon
                    onClick={() => {
                      addToFavourite();
                    }}
                  />
                )}
              </FavouriteView>
              <GView
                style={{
                  display: "flex",
                  gap: itemInfo?.images.length <= 1 ? "0" : "10px",
                  padding: "10px 0",
                }}
              >
                {itemInfo?.images.slice(0, 3).map((img, i) => {
                  return (
                    <React.Fragment key={i}>
                      {i == 0 && (
                        <img
                          alt=""
                          src={`${ImgUrl("item")}/${img.photo}`}
                          onError={addDefaultSrc}
                          className="thumbnail"
                          onClick={() => {
                            setSelectedImgPreview([img.photo]);
                            setImgPreview(true);
                          }}
                          style={{
                            cursor: "pointer",
                            objectFit: "cover",
                            width:
                              itemInfo?.images.length == 1
                                ? "100%"
                                : itemInfo?.images.length == 2
                                ? "50%"
                                : itemInfo?.images.length == 3
                                ? width > 1200
                                  ? "65%"
                                  : "66.5%"
                                : width > 1200
                                ? "65%"
                                : "66.5%",
                            maxHeight:
                              itemInfo?.images.length >= 3
                                ? width > 1200
                                  ? "410px"
                                  : width > 991 && width <= 1200
                                  ? "360px"
                                  : width > 767 && width <= 991
                                  ? "300px"
                                  : "auto"
                                : itemInfo?.images.length == 1
                                ? "300px"
                                : itemInfo?.images.length == 2
                                ? width > 1200
                                  ? "400px"
                                  : width > 991 && width <= 1200
                                  ? "350px"
                                  : width > 767 && width <= 991
                                  ? "300px"
                                  : "auto"
                                : "auto",
                          }}
                        />
                      )}
                    </React.Fragment>
                  );
                })}

                <GView
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    flex: "1",
                  }}
                >
                  {itemInfo?.images.slice(0, 3).map((img, i) => {
                    return (
                      <React.Fragment key={i}>
                        {i > 0 && (
                          <img
                            alt=""
                            src={`${ImgUrl("item")}/${img.photo}`}
                            onError={addDefaultSrc}
                            className="thumbnail"
                            style={{
                              cursor: "pointer",
                              width: "100%",
                              maxHeight:
                                itemInfo?.images.length >= "3"
                                  ? width > 1200
                                    ? "200px"
                                    : width > 991 && width <= 1200
                                    ? "180px"
                                    : width > 767 && width <= 991
                                    ? "150px"
                                    : "auto"
                                  : itemInfo?.images.length == 2
                                  ? width > 1200
                                    ? "400px"
                                    : width > 991 && width <= 1200
                                    ? "350px"
                                    : width > 767 && width <= 991
                                    ? "300px"
                                    : "auto"
                                  : "auto",
                              objectFit: "cover",
                            }}
                            onClick={() => {
                              setSelectedImgPreview([img.photo]);
                              setImgPreview(true);
                            }}
                          />
                        )}
                      </React.Fragment>
                    );
                  })}
                </GView>
                {itemInfo.images.length > 3 && (
                  <GButton
                    variant="linkable"
                    onClick={() => {
                      let previewImages = itemInfo.images.map((img) => {
                        return img.photo;
                      });
                      console.log(previewImages);
                      setSelectedImgPreview(previewImages);
                      setImgPreview(true);
                    }}
                    style={{
                      position: "absolute",
                      background: "#fff",
                      padding: "5px 15px",
                      borderRadius: "4px",
                      fontSize: "14px",
                      fontFamily: "Open Sans, sans-serif",
                      right: width > 767 ? "10px" : "auto",
                      left: width > 767 ? "auto" : "10px",
                      bottom: "20px",
                      color: "#000",
                    }}
                  >
                    {strings.s_show_all_photos}
                  </GButton>
                )}
              </GView>
            </GView>
          )}

          <GView id="productView">
            <GView
              style={{
                width: width > 767 ? "65%" : "100%",
                boxSizing: "border-box",
                paddingRight: width > 767 ? "20px" : "0",
                display: "inline-block",
              }}
            >
              {dO.includes("amenities") && (
                <>
                  {itemInfo.features
                    .filter((f) => f.type == "textarea")
                    .map((feature, i) => {
                      return (
                        <React.Fragment key={i}>
                          <GText
                            g4
                            semi
                            text={feature.title}
                            style={{ margin: "20px 0" }}
                          />
                          <GText text={feature?.value} />
                        </React.Fragment>
                      );
                    })}
                </>
              )}

              {/* ============================ Item Features ============================== */}
              {itemInfo?.features.filter((f) => f.type == "checkbox").length >
                0 && (
                <>
                  <hr
                    style={{
                      margin: "25px 0",
                      border: "0",
                      borderBottom: "1px solid #eee",
                    }}
                  />

                  <GText
                    g4
                    semi
                    text={strings.s_what_this_place_offers}
                    style={{ margin: "20px 0" }}
                  />

                  <GView
                    style={{
                      display: "grid",
                      gridTemplateColumns:
                        width <= 350
                          ? "repeat(auto-fit, minmax(300px, 1fr))"
                          : "repeat(auto-fit, minmax(45%, 1fr))",
                      gridGap: "20px",
                      padding: "10px 0",
                    }}
                  >
                    {itemInfo?.features
                      .filter((f) => f.type == "checkbox")
                      .filter((f) => f.active == true)
                      .slice(0, 8)
                      .map((feature, i) => {
                        return (
                          <GView
                            key={i}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <img
                              src={`${process.env.REACT_APP_API_URL}/features/${
                                feature?.icon || "plus.png"
                              }`}
                              style={{ width: "20px", marginRight: "10px" }}
                            />
                            <GText text={feature?.title} />
                          </GView>
                        );
                      })}
                  </GView>
                  <GButton
                    variant="outlined"
                    children={strings.w_show_all}
                    onClick={() => {
                      setItemAmenitiesVisible(true);
                    }}
                    style={{
                      margin: "20px 0 10px",
                      borderRadius: "5px",
                      color: "#000",
                      border: "1px solid #000",
                    }}
                  />
                </>
              )}

              {/* ============================ Item Features ============================== */}
              {(itemInfo.features.length > 0 && dO.includes("additional")) ||
              itemInfo.price_display ? (
                <>
                  <hr
                    style={{
                      margin: "25px 0",
                      border: "0",
                      borderBottom: "1px solid #eee",
                    }}
                  />

                  <GText
                    g4
                    semi
                    text={strings.w_additional_information}
                    style={{ margin: "20px 0" }}
                  />

                  {itemInfo.price_display && (
                    <GView
                      style={{
                        display: "flex",
                        alignItems: "start",
                        padding: "7px 0",
                      }}
                    >
                      <img
                        src={`${
                          process.env.REACT_APP_API_URL
                        }/features/${"cash.png"}`}
                        style={{
                          width: "18px",
                          marginRight: "15px",
                          paddingTop: "5px",
                        }}
                      />
                      <GView style={{ marginBottom: "5px" }}>
                        <GText
                          semib
                          text={strings.w_price}
                          style={{ marginBottom: "3px" }}
                        />
                        <GText text={itemInfo.price_display} />
                      </GView>
                    </GView>
                  )}

                  {itemInfo.features
                    .filter((f) => f.value)
                    .map((feature, i) => {
                      return (
                        <GView
                          key={i}
                          style={{
                            display: "flex",
                            alignItems: "start",
                            padding: "7px 0",
                          }}
                        >
                          <img
                            src={`${process.env.REACT_APP_API_URL}/features/${
                              feature?.icon || "plus.png"
                            }`}
                            style={{
                              width: "18px",
                              marginRight: "15px",
                              paddingTop: "5px",
                            }}
                          />
                          <GView style={{ marginBottom: "5px" }}>
                            <GText
                              semib
                              text={feature?.title}
                              style={{ marginBottom: "3px" }}
                            />
                            <GText text={feature?.value} />
                          </GView>
                        </GView>
                      );
                    })}
                </>
              ) : (
                ""
              )}

              {/* ============================ Item Reviews ============================== */}
              {dO.includes("reviews") && itemInfo?.reviews.length > 0 && (
                <>
                  <hr
                    style={{
                      margin: "20px 0 25px",
                      border: "0",
                      borderBottom: "1px solid #eee",
                    }}
                  />

                  <GText
                    g3
                    semi
                    text={`★ ${itemInfo?.rating?.avg} • ${itemInfo?.rating?.count} ${strings.w_reviews}`}
                    style={{ margin: "20px 0" }}
                  />

                  {itemInfo?.reviews.map((r, i) => {
                    return (
                      <GView key={i} style={{ margin: "30px 10px" }}>
                        <GInfo
                          title={r?.customer?.title}
                          subtitle={r?.created_short}
                          photo={r?.customer?.thumb_photo}
                          imgType="user"
                          subtitleStyle={{
                            fontSize: "13px",
                            lineHeight: "17px",
                            color: "#555",
                          }}
                          titleStyle={{ fontSize: "16.5px" }}
                          width="40px"
                          height="40px"
                        />
                        <GText
                          text={r.review}
                          style={{
                            padding: "10px 0",
                            fontSize: "14px",
                            lineHeight: "19px",
                          }}
                        />
                      </GView>
                    );
                  })}
                </>
              )}

              {/* ============================ Item Location ============================== */}

              <>
                <hr
                  style={{
                    margin: "20px 0",
                    border: "0",
                    borderBottom: "1px solid #eee",
                  }}
                />
                <GText
                  g4
                  semi
                  text={strings.s_where_you_will_be}
                  style={{ margin: "20px 0" }}
                />

                <LocationDiv>
                  <GMap
                    lat={itemInfo?.lat}
                    lng={itemInfo?.lng}
                    address={itemInfo?.address}
                  />
                  <GView
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "10px 0 0",
                    }}
                  >
                    <GText
                      text={itemInfo?.address}
                      style={{
                        padding: "10px 5px",
                        flex: "1",
                      }}
                    />
                    <span
                      onClick={() => {
                        window.open(
                          `https://maps.google.com?q=${itemInfo?.lat},${itemInfo?.lng}`
                        );
                      }}
                      style={{
                        display: "inline-flex",
                        height: "100%",
                        alignItems: "center",
                        color: "#1a73e8",
                        cursor: "pointer",
                        padding: "0 5px",
                      }}
                    >
                      <NavigateIcon
                        style={{
                          width: "30px",
                          height: "30px",
                          fill: ({ theme }) => theme.body,
                        }}
                      />
                    </span>
                  </GView>
                </LocationDiv>
              </>

              {/* ============================ Item Host Info ============================== */}
              {dO.includes("host") && (
                <>
                  <hr
                    style={{
                      margin: "30px 0",
                      border: "0",
                      borderBottom: "1px solid #eee",
                    }}
                  />

                  <GInfo
                    title={`Hosted by ${itemInfo?.host?.title}`}
                    subtitle={`joined in ${itemInfo?.host?.created}`}
                    photo={itemInfo?.host?.thumb_photo}
                    imgType="host"
                    style={{ marginBottom: "10px" }}
                    subtitleStyle={{
                      fontSize: "13px",
                      lineHeight: "17px",
                      color: "#555",
                      padding: "3px 0",
                    }}
                    width="55px"
                    height="55px"
                  />

                  <GView
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "3px 0",
                    }}
                  >
                    ★ &nbsp;
                    <GText
                      text={`${itemInfo?.host?.rating?.count} reviews`}
                      style={{ fontSize: "14px" }}
                    />
                  </GView>
                  <GView
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "3px 0",
                    }}
                  >
                    ✓ &nbsp;
                    <GText
                      text={"identity Verified"}
                      style={{ fontSize: "14px" }}
                    />
                  </GView>

                  <GText
                    text={itemInfo?.host?.description}
                    style={{ padding: "10px 0", fontSize: "14px" }}
                  />
                </>
              )}
            </GView>

            <SideBar
              style={{
                width: width > 767 ? "35%" : "100%",
                boxSizing: "border-box",
                verticalAlign: "top",
                display: "inline-block",
              }}
              id="sidebar"
            >
              <OrderCheckout
                settings={settings}
                setSigninvisible={setSigninvisible}
                productId={itemInfo?.id}
                userLocation={userLocation}
              />
            </SideBar>
          </GView>
        </GView>
      ) : (
        <GView
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "150px",
          }}
        >
          <GText g3 light bold text={strings.s_placeholder_other_title} />
        </GView>
      )}

      {isImgPreview === true && (
        <GLightBox
          images={selectedImgPreview}
          isImgPreview={isImgPreview}
          setImgPreview={setImgPreview}
          imgType="item"
        ></GLightBox>
      )}

      <Modal
        isOpen={itemAmenitiesVisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setItemAmenitiesVisible(false)}
        style={{
          content: {
            top: "0",
            left: "auto",
            right: "0",
            bottom: "0",
            maxWidth: "420px",
            width: "100%",
          },
        }}
        contentLabel="Item Amenities Modal"
      >
        <ItemAmenities
          itemAmenitiesVisible={itemAmenitiesVisible}
          setItemAmenitiesVisible={setItemAmenitiesVisible}
          data={itemInfo.features}
        />
      </Modal>

      <Modal
        isOpen={signinvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setSigninvisible(false)}
        style={customStyles}
        contentLabel="Login Modal"
      >
        <SignIn
          signinvisible={signinvisible}
          setSigninvisible={setSigninvisible}
          setSignupvisible={setSignupvisible}
          setFpvisible={setFpvisible}
          settings={settings}
        />
      </Modal>

      <Modal
        isOpen={signupvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setSignupvisible(false)}
        style={customStyles}
        contentLabel="Signup Modal"
      >
        <SignUp
          signupvisible={signupvisible}
          setSignupvisible={setSignupvisible}
          setSigninvisible={setSigninvisible}
          settings={settings}
        />
      </Modal>
      <Modal
        isOpen={fpvisible}
        className="modal modalRight"
        overlayClassName="modal-overlay"
        onRequestClose={() => setFpvisible(false)}
        style={customStyles}
        contentLabel="Forgot Password Modal"
      >
        <ForgotPassword
          fpvisible={fpvisible}
          setFpvisible={setFpvisible}
          setSigninvisible={setSigninvisible}
          settings={settings}
        />
      </Modal>
    </GView>
  );
};
export default ItemDetail;
