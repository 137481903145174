import React, { useEffect, useState, useContext, useRef } from "react";
import styled from "styled-components";
import useWindowSize from "../DataFactory/useWindowSize";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import { UserLocContext } from "../DataFactory/useLocation";
import { UserSearchInfoContext } from "../DataFactory/useSearchInfo";
import GView from "../Elements/GView";
import { ReactComponent as LocationIcon } from "../assets/images/location.svg";
import { SettingsContext } from "../DataFactory/useSettings";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import CancelIcon from "../assets/images/cross.png";
import { getFormattedDate } from "./utilCalendar";
import { StringsContext } from "../DataFactory/useStrings";

const Input = styled.input`
  border: 0;
  outline: none;
  width: calc(100% - 30px);
  padding: 5px 10px;
  font-family: Open Sans, sans-serif;
  font-size: 0.95rem;
  font-weight: 600;

  &::placeholder {
    font-weight: 500;
    font-size: 15px;
  }
`;

const SearchDiv = styled.div`
  margin-top: 10px;
  margin-bottom: auto;
  display: flex;
  align-items: center;
  border-radius: 4px;

  .innerContent {
    background: #fff;
    display: flex;
    align-items: center;
    position: relative;
    flex: 1;

    svg {
      width: 20px;
      height: 20px;
      fill: #ccc;
    }
    .DatePicker {
      z-index: 1;
    }
  }
`;

export default function HomeBannerSearch(props) {
  const [strings] = useContext(StringsContext);
  const { settings } = useContext(SettingsContext);
  const [address, setAddress] = useState("");
  const { userLocation, updateLocation } = useContext(UserLocContext);
  const { updateInfo, searchInfo } = useContext(UserSearchInfoContext);
  const { width } = useWindowSize();

  const [range, setRange] = useState(
    searchInfo?.range || { from: null, to: null }
  );

  useEffect(() => {
    setAddress(userLocation?.address);
  }, [userLocation]);

  useEffect(() => {
    if (range) {
      updateInfo({
        ...searchInfo,
        ...{
          range: range,
          starts: getFormattedDate(range.from, true, settings),
          ends: getFormattedDate(range.to, true, settings),
        },
      });
    }
  }, [range]);

  const handleSelect = async (value) => {
    console.log(value);
    const results = await geocodeByAddress(value);
    console.log(results[0].geometry.location);
    setAddress(value);
    let location = {
      lat: results[0].geometry.location.lat(),
      lng: results[0].geometry.location.lng(),
      address: value,
    };
    console.log(location);
    updateLocation(location);
  };

  return (
    <SearchDiv
      style={Object.assign(
        {
          flexDirection: width > 991 ? "row" : "column",
          background: width > 991 ? "#fff" : "transparent",
          gap: width > 991 ? "0" : "10px",
          width:
            width > 991 && width <= 1500
              ? "55%"
              : width > 1500
              ? "50%"
              : "auto",
        },
        props.mainStyle
      )}
    >
      <GView
        className="innerContent"
        style={{
          flex: width > 991 && width <= 1500 ? "20%" : "10%",
          padding: width > 991 ? "15px 10px" : "10px",
          border: width > 991 ? "0" : "1px solid #e2e2e2",
          borderRight: width > 991 ? "2px solid #e2e2e2" : "1px solid #e2e2e2",
          width: width > 991 ? "auto" : "95%",
          minWidth: width > 991 ? "280px" : "auto",
        }}
      >
        <LocationIcon style={{ width: "22px" }} />

        <PlacesAutocomplete
          value={address}
          onChange={setAddress}
          onSelect={handleSelect}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }) => (
            <div style={{ width: "100%", textAlign: "left" }}>
              <Input
                {...getInputProps({
                  placeholder: strings.s_city_landmark_or_address,
                })}
              />

              {suggestions && suggestions.length > 0 ? (
                <div
                  style={{
                    background: "#fff",
                    position: "absolute",
                    left: "0",
                    top: "58px",
                    zIndex: "8",
                    width: "100%",
                    maxWidth: "450px",
                    boxSizing: "border-box",
                    padding: "0 5px 5px",
                    border: "1px solid rgb(226, 226, 226)",
                  }}
                >
                  <ul
                    style={{
                      listStyleType: "none",
                      margin: "0",
                      maxHeight: "220px",
                      overflow: "overlay",
                      textAlign: "left",
                      display: "block",
                      padding: "0",
                    }}
                  >
                    {suggestions.map((suggestion, ind) => {
                      const style = {
                        display: "flex",
                        backgroundColor: suggestion.active
                          ? "#e2e2e2"
                          : "#ffffff",
                        marginRight: "10px",
                        cursor: "pointer",
                        color: "#2D2F33",
                        borderBottom: "1px solid #e2e2e2",
                        alignItems: "center",
                        padding: "8px 10px",
                      };
                      return (
                        <li
                          key={ind}
                          {...getSuggestionItemProps(suggestion, { style })}
                        >
                          <LocationIcon
                            style={{
                              fontSize: "18px",
                              color: "#ccc",
                              verticalAlign: "middle",
                            }}
                          />{" "}
                          <p
                            style={{
                              margin: "0",
                              marginLeft: "10px",
                              color: "#333",
                              width: "calc(100% - 30px)",
                            }}
                          >
                            {suggestion.description}
                          </p>
                          <br />
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                ""
              )}
            </div>
          )}
        </PlacesAutocomplete>
        {userLocation?.address && (
          <img
            src={CancelIcon}
            style={{ width: "15px", cursor: "pointer" }}
            onClick={() => {
              setAddress("");
              updateLocation({});
            }}
          />
        )}
      </GView>
    </SearchDiv>
  );
}
